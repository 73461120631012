<template>
  <div class="channel-edit">
      <van-cell center :border="false">
          <div 
            slot="title"
            class="channel-title"
          >我的频道</div>
          <van-button
            type="danger"
            plain
            round
            size="mini"
            @click="isEdit = !isEdit"
          >
            {{isEdit ? '完成' : '编辑'}}
          </van-button>
      </van-cell>

      <van-grid :gutter="10">
        <van-grid-item 
            class="grid-item"
            :class="{ active: index === active }"
            v-for="(channel, index) in userChannels" 
            :icon="(isEdit && index !=0) ? 'clear' : ''"
            :key="index" 
            :text="channel.name" 
            @click="onUserChannelClick(channel,index)"
        />
      </van-grid>

      <van-cell center :border="false">
          <div 
            slot="title"
            class="channel-title"
          >频道推荐</div>
      </van-cell>

      <van-grid :gutter="10">
        <van-grid-item 
            class="grid-item"
            v-for="(channel, index) in recommendChannels" 
            :key="index" 
            :text="channel.name"
            @click="onAdd(channel)" 
        />
      </van-grid>
  </div>
</template>

<script>
import { getAllChannels, addUserChannel, deleteUserChannel } from '@/api/channel'
import { mapState } from 'vuex'
import { setItem } from '@/utils/storage'

export default {
  name: 'ChannelEdit',
  components: {},
  props: {
      userChannels: {
          type: Array,
          require: true
      },
      active: {
          type: Number,
          require: true
      }
  },
  data() {
    return {
        allChannels: [], // 所有频道数据列表
        isEdit: false // 控制编辑的显示状态
    };
  },
  computed: {
      ...mapState(['user']),

      // 推荐的频道列表
      recommendChannels() {
          // 思路： 所有频道 - 我的频道 = 剩下的推荐频道
          // filter 方法：过滤数据，根据方法返回的布尔值 true 来收集数据
          // filter 方法查找满足条件的所有元素
          return this.allChannels.filter(channel => {
              // 判断 channel 是否属于用户频道
              // find 方法查找满足的单个元素
              return !this.userChannels.find(userChannel => {
                  // 找到满足该条件的元素
                  return userChannel.id === channel.id
              })
          })

          // 以前方法
        //   const arr = []
        //   this.allChannels.forEach(channel => {
        //       let flag = false
        //       for(let i = 0; i < this.userChannels.length; i++) {
        //           if(this.userChannels[i].id === channel.id) {
        //               flag = true
        //               break
        //           }
        //       }
        //       if(!flag) {
        //           arr.push(channel)
        //       }
        //   });
        //   return arr
      }
  },
  watch: {},
  created() {
      this.loadAllChannels()
  },
  mounted() {},
  methods: {
      async loadAllChannels() {
          const { data } = await getAllChannels()
          this.allChannels = data.data.channels
      },

      async onAdd(channel) {
          this.userChannels.push(channel)
        
          // TODO：数据持久化
          if(this.user) {
              // 登录,就存储到线上
              await addUserChannel({
                  channels: [
                      {
                          id: channel.id, 
                          seq: this.userChannels.length 
                      }
                  ]
              })
          } else {
              // 没有登录,就存储到本地
              setItem('user-channels', this.userChannels)
          }
      },

      onUserChannelClick(channel, index) {
          if(this.isEdit  && index != 0) {
              // 编辑状态，删除频道
              this.deleteChannel(channel, index)
          } else {
              // 非编辑状态，切换频道
              this.switchChannel(index)
          }
      },

      async deleteChannel(channel, index) {
          // 判断是否是激化频道的前面
          if(index <= this.active) {
              // 如果是删除激活前的频道就减 1
              this.$emit('update-active', this.active - 1)
          }

          this.userChannels.splice(index, 1)

          // 数据持久化
          if(this.user) {
              // 登陆了，持久化到线上
              await deleteUserChannel(channel.id)
          } else {
              // 没有登录保存到本地
              setItem('user-channels', this.userChannels)
          }
      },

      switchChannel(index) {
          // 切换频道
          this.$emit('update-active', index)
          
          // 关闭弹出层
          this.$emit('close')
      }
  }
};
</script>
<style lang="less" scoped>
.channel-edit {
    padding-top: 54px;

    .channel-title {
        font-size: 16px;
        color: #333333;
    }

    .grid-item {
        width: 80px;
        height: 43px;
        /deep/ .van-grid-item__content {
            background-color: #f4f5f6;
            .van-grid-item__text {
                font-size: 14px;
                color: #222;
                margin-top: 0; 
            }
        }
        /deep/ .van-grid-item__icon {
            position: absolute;
            right: -5px;
            top: -5px;
            font-size: 20px;
            color: #ccc;
        }
    }

    .active {
        /deep/ .van-grid-item__text {
            color: red !important;
        }
    }
}
</style>