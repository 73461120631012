<template>
  <div class="home-container">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-notice-bar v-if="notice_text != ''" background="#f0dadc" color="black" left-icon="volume-o" :text="notice_text" />
      <!-- <p>刷新次数: {{ count }}</p> -->
      <div class="index-top">
        <div class="top-title">
          <!-- <span @click="selecthref('selectcity')">{{ city }}</span> -->
          <span>{{ city }}</span>
          <!-- 搜索 -->
          <!-- <van-icon @click="selecthref('selectcity')" name="location-o" color="#666666" size="0.5rem" /> -->
          <van-icon name="location-o" color="#666666" size="0.5rem" />
          <van-search @click="selecthref('goodslist', { show: true })" v-model="value" show-action shape="round" placeholder="请输入搜索内容">
            <template #action>
              <!-- <div style="font-size: 0.33rem;border-radius: 1rem;padding: 0 0.5rem;background-color: #E60027;color: #FFFFFF;">搜索</div> -->
            </template>
          </van-search>
          <div @click="chandExit"> <img src="../../assets/img/exit.png" style="width: 0.5rem;" /></div>
        </div>
        <!-- 轮播图 -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="i in  bannerlist " @click='home_action(i)' :key=i.id>
            <img :src="i.picture">
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="member"> </div>
      <!-- <div>
        <img src="../../assets/img/member.png" style="width: 100%; height: 100%;">
      </div> -->
      <!-- 导航栏目 -->
      <div class="index-nav">
        <van-grid :border="false" :column-num="navlistlength" class="">
          <van-grid-item @click='home_action(i)' v-for=" i  in  navlist " :key=i.id>
            <img :src="i.picture" />
            <span>{{ i.name }}</span>
          </van-grid-item>
        </van-grid>
      </div>
      <!--广告位 -->
      <div class="index-banner" v-for=" i  in  articlelist " :key='i.id'>
        <div>{{ i.name }}</div>
        <div @click='home_action(i)'>
          <img :src="i.picture" />
        </div>
      </div>
      <!-- 定位说明 -->
      <div class="positioning " v-if="positioning==true">
        <van-icon name="location-o" color="#666666" size="0.5rem" />
        <div class="post">
          <span>位置权限使用说明</span>
          <span>根据您的定位信息为您推荐周边的生活服务</span>
        </div>
      </div>
      <!-- 优惠券 -->
      <div style="width: 95%;margin: 0 auto;">
        <!-- <div class="list-title" @click="sheetshow = true"> -->
        <div class="list-title">
          <span> {{ couponTitle }}</span>
          <span @click="repositioning">获取定位</span>

        </div>
        <div class="index-list">
          <div class="list-content" @click="selecthref('scdetail', { detailid: i.id })" v-for="i in goodslist" :key="i.id">
            <div class="list-img">
              <img :src="i.logo">
            </div>
            <div class="list-info">
              <span>{{ i.storeName }}</span>
              <span>满{{ i.couponType }}.10元可用</span>
              <span>
                {{ i.address }}
              </span>
            </div>
          </div>
        </div>
        <div @click="selecthref('goodslist', { couponType: couponType, couponTitle: couponTitle })" class="index-bottom" style="margin-bottom:0.35rem">
          <van-loading v-show="loading == '1'" size="24px">加载中...</van-loading>
          <span v-show="loading == '0'">查看更多</span>
          <span v-show="loading == '3'" style="color: #6A737D;">已无更多</span>
        </div>

      </div>
      <!-- 互联网权益券 -->
      <!-- <div v-show="showIntnet" style="width: 95%;margin: 0 auto;">
        <div class="list-title">
          <span> {{ couponTitle1 }}</span>
        </div>
        <div class="index-list">
          <div class="list-content" @click="selecthref('scdetail1', { detailid: i.id })" v-for=" i  in  goodslist1 " :key=i.id>
            <div class="list-img">
              <img :src="i.picture">
            </div>
            <div class="list-info">
              <span>{{ i.name }}</span>
              <span style="margin-bottom: 0.1rem;margin-top: 0.15rem;    background: none;
    text-align: left;
    font-size: 0.35rem;
    font-weight: 700;"><a v-if="i.receiveType == '0'">免费领取</a></span>
              <span style="    color: #999999;
    font-size: 0.35rem; text-decoration: line-through;">
                {{ i.price }}
              </span>
            </div>
          </div>

        </div>
        <div @click="selecthref('goodslist1')" class="index-bottom">
          <van-loading v-show="loadings == '1'" size="24px">加载中...</van-loading>
          <span v-show="loadings == '0'">查看更多</span>
          <span v-show="loadings == '3'" style="color: #6A737D;">已无更多</span>
        </div>

      </div> -->

      <van-popup round v-show="sheetshow" closeable position="bottom">

        <div style="margin-top: 1rem;">
          <span @click="onSelect(i)" style="display: block; width: 100%; padding: 0.37333rem; font-size: 0.37333rem;background-color: #fff;
    border: none; cursor: pointer;border-bottom: 0.01rem solid;
  " v-for=" i  in  actions " :key="i.id">
            {{ i.name }}
          </span>
        </div>
        <div style="padding: 0.5rem 0;">

          <van-field v-model="sms" center clearable label="输入手机号" placeholder="请输入手机号">
            <template #button>
              <van-button @click='onClickSelect' size="small" type="primary">确定</van-button>
            </template>
          </van-field>

        </div>

      </van-popup>

      <!-- <van-action-sheet close-on-click-action title='切换用户状态' v-model:show="sheetshow" :actions="actions"
        @select="onSelect" /> -->
      <van-dialog @confirm="selecthref('getuser')" v-model="show" :title="articlec" show-cancel-button>
        <div v-html="articlec_content" style="font-size: 0.3rem;padding:  0.3rem 0.5rem;">
        </div>
      </van-dialog>
      <!--  <van-dialog :showConfirmButton='usertitleshow' @confirm="selecthref('getuser')" :confirmButtonText='usertitle' v-model="usershow"  show-cancel-button>
        <div v-html="articlec_content" style="font-size: 0.3rem;padding:  0.3rem 0.5rem;">
        </div>
      </van-dialog> -->

      <!-- <van-action-sheet v-model:show="usershow" :title="usertitle"> -->
      <van-popup round v-show="usershow" closeable position="bottom">

        <div style="    text-align: center;
    margin-top: 0.2rem;" class="popups">
          <img v-show="userType == '0'" @click='userStatus(0)' style="width: 0.8rem;" src="../../assets/img2/member.png" />
          <img v-show="userType == '1' && mobile == '15653103741'" @click='userStatus(1)' style="width: 0.8rem;" src="../../assets/img2/memberbj.png" />
          <img v-show="userType == '1' && mobile == '15028155600'" @click='userStatus(1)' style="width: 0.8rem;" src="../../assets/img2/memberhj.png" />
          <span class="popups-title" style="color: #333333;" v-show="userType == '0'">尊敬的用户</span>
          <span class="popups-title" style="color:#8B4927" v-show="userType == '1' && mobile == '15653103741'">尊敬的铂金会员</span>
          <span class="popups-title" v-show="userType == '1' && mobile == '15028155600'">尊敬的黄金会员</span>
        </div>
        <div class="contents" style="font-size: 0.4rem;padding: 0.5rem;line-height: 0.6rem;    background: #F7F7F7;
         width: 80%;
         margin: 0.2rem auto;" v-show="userType == '0'">
          您尚未开通任何 PLUS会员，开通会员后每月可享受以下特权 <br />
          <span style="display: block;font-weight: 700;">黄金会员权益<br /></span>
          黄金会员8.99元/月<br />
          每个月可领取1GB全国通用流量<br />
          每个月可领取1张20元知名商超代金券（消费满20.10元）<br />
          <span style="display: block;font-weight: 700;">铂金会员权益<br /></span>
          黄金会员9.99元/月<br />
          每个月可领取2GB全国通用流量<br />
          每个月可领取1张20元知名商超代金券（消费满20.10元）<br />
        </div>
        <div v-show="userType != '0'" class="contents" style="font-size: 0.4rem;padding: 0.5rem;line-height: 0.6rem;    background: #F7F7F7;
    width: 80%;
    margin: 0.2rem auto;">
          <span style="display: block;font-weight: 700;">{{ usertitle }}权益</span>

          <span v-show="userType == '1' && mobile == '15028155600'">每个月可领取1GB全国通用流量 <br /></span>
          <span v-show="userType == '1' && mobile == '15653103741'">每个月可领取2GB全国通用流量<br /></span>
          每个月可领取1张20元知名商超代金券（消费满20.10元可用）
          <span class="yiling" v-show="usernum > 0">本月已领</span>
          <span class="huangjins" v-show="usernum <= 0 && userType == '1' && mobile == '15028155600'">本月未领</span>
          <span class="bojins" v-show="usernum <= 0 && userType == '1' && mobile == '15653103741'">本月未领</span>

          <span style="display: block;font-weight: 700;">权益领取</span>
          通过“ 移动”APP进行领取。 <br />
          领取后需关注“ 移动”微信公众号进行激活 <br />
          激活后在相应的线下门店消费满20.10元即可使用，在线下门店进行结算时使用微信支付即可自动使用20元代金券。 <br />
        </div>
        <button :class="[userType == '1' && mobile == '15028155600' ? 'huangjin' : 'bojin']" @click="selecthref('quanyi')" v-show="usernum > 0 && userType != '0'" style="font-size: 0.5rem;">
          <span>查看权益</span>
        </button>
        <button :class="[userType == '1' && mobile == '15028155600' ? 'huangjin' : 'bojin']" @click="selecthref('goodslist')" v-show="usernum <= 0 && userType != '0'" style="font-size: 0.5rem;">
          <span>立即领劵</span>
        </button>
        <button @click="selecthref('member')" v-show="usernum <= 0 && userType == '0'" style="font-size: 0.5rem;
          width: 100%;
          padding: 0.3rem;
              background: linear-gradient(to bottom right,#FF607B, #E50127);
          color: #fff;
          border: 0;
          letter-spacing: 0.2rem;
          text-align: center;">
          <span>立即开通</span>
        </button>
      </van-popup>

    </van-pull-refresh>
  </div>
</template>

<script>
import router from '@/router/index.js'
import {
  getColumns,
  getCouponList,
  getCommonArticle,
  getuserInfo,
  haveCoupon,
  getToken,
  getTokenForTicket,
  internetList,
  getUserCity,
  getNotice,
  generateScheme,
  getUserArea
} from '@/api/user'
import {
  loadbmap
} from '@/api/loadbmap'
import ArticleList from './components/article-list.vue'
import ChannelEdit from './components/channel-edit.vue'
import {
  mapState
} from 'vuex'
import {
  getItem,
  setItem
} from '@/utils/storage'
import {
  Toast,
  Dialog,
  showConfirmDialog
} from 'vant';
export default {
  name: 'home',
  components: {
    // ArticleList,
    // ChannelEdit
  },
  props: {},
  data() {
    return {
      status2: '',
      sheetshow: false,
      positioning: false,
      sms: '',
      usertitle: '',
      size: '100',
      notice_text: '',
      isLoading: false,
      usertitleshow: true,
      usershow: false,
      couponTitle1: '互联网权益券',
      actions: [{
        name: '会员1韩',
        value: '15653103741',
        className: 'sheetbutton'
      },
      {
        name: '非会员',
        value: '18513595259',
        className: 'sheetbutton'
      },
      {
        name: '会员2',
        value: '15028155600',
        className: 'sheetbutton'
      },

      ],
      goodslist1: [


      ],

      BMap: null,
      usernum: '',
      geolocation: null, // Geolocation对象实例
      positioning: false, // 定位中
      positioningInterval: null, // 定位倒计时计时器
      countDown: 30, // 倒计时，单位秒
      location: null, // 位置信息
      active: 0, //被激活的列表
      channels: [], //频道列表
      city: '',
      bannerlist: [], //轮播图列表
      goodslist: [], //商品列表
      longitude: '', //经度
      latitude: '', //纬度
      areaId: '', //城市id,
      ltAreaCode: '',
      from: 1,
      couponTitle: '',
      mobile: '',
      show: false,
      articlec: '',
      articlec_content: '',
      couponType: '',
      loading: '0',
      loadings: '0',
      datas: {
        city: ''
      },
      userType: '',
      value: '',
      isloop: 1,
      navlist: [], //导航列表
      articlelist: [], //广告列表
      navlistlength: 0, //导航列表长度
      isChannelEditShow: false, //频道编辑层,
      showIntnet: false,//是否显示互联网权益
    }
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {
    '$route'() {
      location.reload()
      this.$dialog.close()
      this.show = false
    }

  },
  async created() {
    // alert(window.location.href)
    // if (sessionStorage.getItem('ticket') != '1') {
    //   //如果ticket在运行期间存在 就不在请求
    //   var ticket = this.geturl();
    //   if (ticket != '') {
    //     var mobile = await this.geticket(ticket)
    //     await this.get_token(mobile)
    //     // sessionStorage.setItem('ticket', '1')
    //   }
    // }

  },
  async mounted() {
    const _this = this
    var datas = await getNotice(
      { noticeType: '1', cityCode: this.areaId }
    )
    if (datas.data.code == "0") {
      this.notice_text = datas.data.data
    }
    _this.getScheme()

    if (getItem('longitude') == null || getItem('latitude') == null) {
      _this.longitude = '108.95348289551913'
      _this.latitude = '34.26558068956188'
      setItem('latitude', _this.latitude)
      setItem('longitude', _this.longitude)
    } else if (getItem('longitude') !== null || getItem('latitude') !== null) {
      _this.latitude = getItem('latitude')
      _this.longitude = getItem('longitude')
    }
    if (getItem('localtion') !== null) {
      // console.log('进入localtion')
      _this.onload()

    }
    this.record({
      "type": 1,
      "resourceId": ''
    })




    // if (getItem('latitude') != '' && getItem('latitude') != null && getItem('latitude') != 1) {
    //   _this.latitude = getItem('latitude')
    //   _this.longitude = getItem('longitude')
    //   // _this.city = '滨州市'
    //   if (getItem('localtion') !== null) {
    //     _this.city = getItem('localtion').name
    //     console.log('城市', _this.city)
    //     _this.areaId = getItem('localtion').id
    //     _this.ltAreaCode = getItem('localtion').ltAreaCode
    //   }
    //   _this.onload()
    // } else {
    //   Toast.loading({
    //     message: '获取当前定位中请稍等',
    //     forbidClick: false,
    //     duration: 0,
    //     overlay: true
    //   });
    //   window.initBaiduMapScript = () => {
    //     _this.BMap = window.BMap
    //     // console.log("  _this.BMap", _this.BMap);
    //     _this.getlocation()
    //   }
    //   loadbmap('initBaiduMapScript')
    // }



  },
  methods: {
    // 获取Scheme url
    async getScheme() {
      const {
        data
      } = await generateScheme()
      this.schemeurl = data.data
      // console.log('获取url', this.schemeurl)
    },
    // 重新定位
    repositioning() {
      this.positioning = true
      Dialog.confirm({
        title: "温馨提示",
        message:
          "https://rights-test.gdyuanpeng.com/想获取您的位置信息。",
        confirmButtonText: "允许",
        cancelButtonText: "不允许",
        confirmButtonColor: "#FFFFFF",
        messageAlign: "left",
        className: "czq_confirm",
      }).then(async () => {
        console.log('成功')
        this.position()
        this.positioning = false;
      }).catch(() => {
        this.positioning = false;
      });

      setTimeout(() => {

      }, 5000);
    },
    position() {
      Toast.loading({
        message: '获取当前定位中请稍等',
        forbidClick: false,
        duration: 0,
        overlay: true
      });
      var userAgent = navigator.userAgent.toLowerCase();
      var ishsq = userAgent.match(/(sxhsq|hsq)/i);
      console.log('ishsq', ishsq)
      if (ishsq) {
        // 获取定位信息
        flutterJsChannel.postMessage("{\"type\":\"getLocationPermissionStatus\",\"data\":\"\"}");
        window.flutterCallJsMethod = this.flutterCallJsMethod
      }
    },
    // 客户端返回数据
    flutterCallJsMethod(message) {
      console.log('json', message)
      switch (message['type']) {
        case 'getLocation':
          //返回的是一个json串
          var obj = JSON.parse(message['data']);
          //经度
          var longitude = obj['longitude'];
          //纬度
          var lati = obj['latitude'];
          //地理位置
          var addres = obj['addres'];
          //城市
          var city = obj['city'];
          setItem('latitude', lati)
          setItem('longitude', longitude)
          setItem('addressCity', city)
          this.getuserAgent()
          // console.log('json格式', obj)
          break;
        case 'getLocationPermissionStatus':
          Toast.clear()
          console.log('state', message, message.data, message['data'])
          var state = message['data']; //0-关闭 1-开启
          console.log('state2', state)
          if (state == 1) {
            //用户开启定位授权，获取位置信息
            flutterJsChannel.postMessage("{\"type\":\"getLocation\",\"data\":\"\"}");
          } else if (state == '0') { //用户未开启定位授权
            break;
          }
          break;
        default:
          break;
      }
      return "";
    },
    // 获取手机型号
    getuserAgent() {
      var userAgent = navigator.userAgent;
      // 判断是否为iOS设备
      function isIOS() {
        return /(iPhone|iPad|iPod)/i.test(userAgent);
      }
      // 判断是否为Android设备
      function isAndroid() {
        return /android/i.test(userAgent) && !isIOS(); // Android不能同时被识别为iOS设备
      }
      if (isIOS()) {
        this.plantForm = 1
        console.log("这是一台iOS设备");
      } else if (isAndroid()) {
        this.plantForm = 0
        console.log("这是一台Android设备");
      } else {
        this.plantForm = ''
        console.log("未知操作系统");
      }
      if (getItem('longitude') !== null) {
        let city = getItem('addressCity')
        this.latitude = getItem('latitude')
        this.longitude = getItem('longitude')
        getUserArea({ baiduMapCity: city }).then(res => {
          if (res.data.code == 0) {
            console.log('获取城市', res.data)
            this.city = res.data.data.name
            console.log('城市', this.city)
            this.CouponList()
          }
        });
      }
      Toast.clear()
    },



    // 

    // 定位
    async dinfwei() {

      const _this = this

      window.initBaiduMapScript = () => {
        _this.BMap = window.BMap
        // console.log("  _this.BMap", _this.BMap);
        _this.getlocation()
      }
      loadbmap('initBaiduMapScript')
      if (sessionStorage.getItem('ticket') != '1') {
        // console.log(进入)
        //如果ticket在运行期间存在 就不在请求
        var ticket = this.geturl();
        var value = this.getValue();
        if (ticket != '' || value != '') {
          var mobile = await this.geticket(ticket, value)
          sessionStorage.setItem('ticket', '1')
        }
      }
      setItem('show', false)
      this.mobile = getItem('mobile')
      const city = ''
      _this.latitude = getItem('latitude')
      _this.longitude = getItem('longitude')
      if (getItem('localtion') !== null) {
        _this.city = getItem('localtion').name
        _this.areaId = getItem('localtion').id
        _this.ltAreaCode = getItem('localtion').ltAreaCode
      }
      // _this.onload()
    },
    geturl() {
      try {
        var url = window.location.href; //获取当前url
        // var dz_url = url.split('#')[1]; //获取#/之后的字符串
        var dz_url = url.split('#')[0]; //获取#/之前的字符串
        var cs = dz_url.split('?')[1]; //获取?之后的参数字符串
        var cs_arr = cs.split('&'); //参数字符串分割为数组
        var cs = {};
        for (var i = 0; i < cs_arr.length; i++) { //遍历数组，拿到json对象
          cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
        }
        return decodeURIComponent(cs.ticket);
      } catch (e) {
        return ''
        //TODO handle the exception
      }

    },
    getValue() {
      try {
        var url = window.location.href; //获取当前url
        // var dz_url = url.split('#')[1]; //获取#/之后的字符串
        var dz_url = url.split('#')[0]; //获取#/之前的字符串
        var cs = dz_url.split('?')[1]; //获取?之后的参数字符串
        //var cs_arr = cs.split('&'); //参数字符串分割为数组
        //var cs = {};
        //for (var i = 0; i < cs_arr.length; i++) { //遍历数组，拿到json对象
        //  cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
        //}
        //return decodeURIComponent(cs.value);

        var reg = new RegExp('(^|&)' + 'value' + '=([^&]*)(&|$)', 'i');
        var r = cs.match(reg);
        if (r != null) {
          return unescape(r[2]); //unescape()将url编码字符串转换为正常编码。
        }
        return null;
      } catch (e) {
        return ''
        //TODO handle the exception
      }
    },

    getUrlParam(name) {
      //name为要获取的参数名
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var rrr = decodeURIComponent(window.location.search);
      var r = rrr.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },


    async geticket(userInfo, value) {
      let parma = {
        "ticket": userInfo,
        "value": value
      }
      const {
        data
      } = await getTokenForTicket(parma)
      setItem('mobile', data.data)
      return data.data


    },

    async userinfo() {

      const {
        data
      } = await getuserInfo()
      this.userType = data.data.userType
      const
        datas = await haveCoupon()
      this.usernum = datas.data.data

      // if (this.mobile != '15653103741' && this.userType != '0') {
      // this.mobile = '15028155600'
      // this.userType = '1'
      // }
      // this.finished=true
    },
    onSelect(item) {
      this.mobile = item.value
      setItem('mobile', item.value)
      this.sheetshow = false
      this.$router.go(0)
    },
    onClickSelect() {
      this.mobile = this.sms
      setItem('mobile', this.mobile)
      this.sheetshow = false
      this.$router.go(0)
    },
    home_action(i) {
      switch (i.linkType) {
        case 0: //打开弹窗 内部元素
          // this.commonArticleclick()
          if (this.ltAreaCode == 170) {
            Dialog.alert({
              message: '当前未开启线上订购业务，敬请期待!',
            })
          } else if (this.userType == '1') {
            Dialog.alert({
              message: '尊敬的会员您已开通会员，无需重复开通',
            }).then(() => {
              // on close
            });
            break;
          } else {
            let userArea = getItem('userArea')
            if (userArea.id == 15) {
              this.selecthref('member')
            } else {
              this.selecthref('vipTrip')
            }
          }
          break;
        case 1: //跳转内部页面
          switch (i.sourceType) {
            case 1: //打开首页
              this.$router.go(0)
              break;
            case 2: ///打开个人中心
              break;
            case 3: ///我的优惠卷
              let userArea = getItem('userArea')
              if (userArea.id == 15) {
                this.selecthref('ljjl')
              } else {
                this.selecthref('ljjl1')
              }
              break;
            case 4: ///打开商超卷列表
              this.selecthref('goodslist')
              break;
            case 5: ///领券说明
              this.selecthref('ljsm', { id: this.areaId })
              break;
            // case 3: //产品说明
            //   this.selecthref('description')
            //   break;
          }

          break;
        case 2: //打开弹窗 内部元素
          window.location.href = i.outLinkUrl
          break;
        default:

      }
    },
    async commonArticleclick() {
      let param = {
        type: "0",
        'cityId': getItem('userArea').id
      }
      var that = this
      const {
        data
      } = await getCommonArticle(param)
      this.show = true
      this.articlec = data.data[0].title
      this.articlec_content = data.data[0].content
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
      this.from = 1;
      this.goodslist = []
      this.goodslist1 = []
      this.onload()
    },
    async onload() {
      await this.Columns();
      await this.userinfo();
      await this.CouponList();
    },
    selecthref(href, data = '') {
      // if(href == 'lingqu'){
      //   return;
      // }
      if (data != '') {
        setItem('show', true)
      }
      // console.log('AAA', data);
      this.$router.push({
        name: href,
        params: data,
      })
    },
    getlocation() {

      const _this = this
      Toast.loading({
        message: '获取当前定位中请稍等',
        forbidClick: false,
        duration: 0,
        overlay: true
      });
      let baidu = new _this.BMap.Geolocation()
      var city = ''
      baidu.getCurrentPosition((r) => {
        _this.latitude = r.latitude
        _this.longitude = r.longitude
        setItem('latitude', _this.latitude)
        setItem('longitude', _this.longitude)
        setItem('addressCity', r.address.city)

        // console.log('定位')
        _this.CouponList()
        Toast.clear()
      }, (error) => {
        Toast.clear()
        // _this.onload()

      }, {
        Timeout: '10000'
      })


    },

    async Columns() {
      var that = this
      that.ltAreaCode = getItem('localtion').ltAreaCode
      // console.log('ltAreaCode', that.ltAreaCode)
      const {
        data
      } = await getColumns({ ltAreaCode: that.ltAreaCode })

      data.data.forEach((i, k) => {
        let type = i.type
        switch (type) {

          case 0:
            that.bannerlist = i.data
            break;
          case 1:
            that.navlist = i.data
            that.navlistlength = i.data.length
            //
            // that.bannerlist = i.data
            break;
          case 2:
            that.articlelist = i.data

            break;
          case 3:
            that.couponTitle = i.title
            that.couponType = i.couponType
            this.status2 = type
            // console.log('111', this.status2);
            break;
          case 4:
            // this.couponTitle1 = i.title
            this.showIntnet = true
            that.getInternetList()
          default:
            break;
        }
      })
    },
    // 列表
    async CouponList() {
      Toast.loading({
        message: '加载中...',
        forbidClick: false,
        duration: 0,
        overlay: true
      });
      console.log('城市', this.city)
      let param = {
        latlon: this.latitude + ',' + this.longitude,
        couponType: this.couponType,
        areaId: this.areaId,
        from: this.from,
        size: 3,
        areaName: this.city,
        couponClassify: 0,
        plantForm: this.plantForm
      }
      console.log('列表参数', param)
      const { data } = await getCouponList(param)
      Toast.clear()
      this.goodslist = data.data
      this.city = getItem('localtion').name
      if (data.code == 100115) {
        let toutiaoUser = JSON.parse(localStorage.getItem('toutiao-user'))
        let token = toutiaoUser.token
        // let url = this.schemeurl + '&cq=' + token
        // console.log('获取url', url)
        Dialog.alert({
          title: '',
          confirmButtonText: '确定',
          message: '您还未授权，请到小程序授权！',
        })
          .then(() => {
            location.href = this.schemeurl + '&cq=' + token

          });
      }
      if (JSON.stringify(this.goodslist) === '[]') {
        this.goodslist = data.data
      }
      // else {
      //   data.data.forEach((i, k) => {
      //     this.goodslist.push(i)
      //   })
      // }
      if (JSON.stringify(data.data) === '[]') {

        this.loading = 3
      } else {
        this.loading = 0
      }
    },
    async getInternetList() {
      let param = {
        from: this.from,
        size: 3,
      }
      const {
        data
      } = await internetList(param)

      // this.goodslist = data.data
      if (JSON.stringify(this.goodslist1) === '[]') {
        this.goodslist1 = data.data
      } else {
        data.data.forEach((i, k) => {
          this.goodslist1.push(i)
        })
      }
      if (JSON.stringify(data.data) === '[]') {
        this.loadings = 3
      } else {
        this.loadings = 0
      }
    },
    userStatus(val) {

      if (val == 0) {
        this.usertitleshow = true
        this.usertitle = '立即开通'
        this.usershow = true
        this.articlec_content = '<span style="font-size:0.5rem">您尚未开通会员，开通会员后每个月可领取一张价值20元的商超代金券一张</span>'
      } else {

        if (this.mobile == '15653103741') {
          this.usertitle = '铂金会员'
        } else {
          this.usertitle = '黄金会员'
        }
        this.usershow = true
        // if(this.usernum > 0){
        //   this.usertitleshow = false
        //   this.usershow = true
        //   this.articlec_content = '<span style="font-size:0.5rem">每个月商超代金券每人限领1张，您本月已领取欢迎次月再来领取。</span>'
        // }else{
        //   this.selecthref('goodslist')
        // }

      }

    },
    // 退出登录
    chandExit() {
      // this.$store.commit('setUser')
      localStorage.clear()
      setItem("localtion", '');
      this.$router.push({ name: 'equityLogin' })
    },
  }
}
</script>
<style lang="less">
.van-grid-item__content {
  padding: 0.42667rem 0rem;
}
</style>
<style scoped lang="less">
.sheetbutton {
  border-bottom: 1px solid;
  // background-color: #a73636;
}

.my-swipe .van-swipe-item {
  width: 100%;
  height: 2rem;
  background: #fff;
  // color: #fff;
  // font-size: 20px;
  // line-height: 150px;
  // text-align: center;
  // background-color: #39a9ed;

  img {
    width: 100%;
    height: 100%;
  }
}

.van-search--show-action {
  padding: 0;
  margin: 0 0.2rem;
  border-radius: 10rem !important;
}

.van-search__action {
  padding: 0;
}

.van-search {
  width: 65%;
  // width: 60%;
  background-color: #fff;
}

.van-search__content--round {
  background-color: #ffffff;
}

.home-container {
  .index-top {
    background-color: #f4f4f4;
    width: 100%;
    // height: 2.5rem;
  }

  .top-title {
    display: flex;
    /* align-content: center; */
    align-items: center;
    width: 95%;
    margin: 0 auto;
    padding-top: 0.2rem;
    font-size: 0.35rem;
    justify-content: center;

    span {
      padding: 0 0.09rem;
    }
  }

  .index-nav {
    font-size: 0.3rem;
    font-weight: 700;
    // margin: 0.2rem 0;

    img {
      height: 0.8rem;
      width: 0.8rem;
    }

    span {
      padding-top: 0.15rem;
      color: #333333;
    }
  }

  .index-banner {
    width: 88%;
    margin: 0 auto;
    background: #fff;
    margin-top: 0.3rem;
    font-size: 0.4rem;
    color: #333333;
    font-weight: 700;
    padding: 0.3rem;
    padding-bottom: 0;
    position: relative;

    div {
      margin-bottom: 0.2rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .list-title {
    background-image: url(../../assets/img/navs.png);
    font-size: 0.4rem;
    width: 100%;
    height: 1rem;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;

    span {
      display: block;
      padding-top: 0.3rem;
      font-weight: 700;
      padding-left: 0.3rem;
      // color: #fff;
      &:nth-child(2) {
        padding-right: 0.3rem;
        font-weight: 500;
      }
    }
  }

  .list-content {
    background: url(../../assets/img2/goodslistbg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 0.2rem;
    margin-bottom: 0.3rem;
    display: flex;
    align-items: center;

    display: flex;
    // background: #fff;
    padding: 0.3rem 0.5rem;

    img {
      display: block;
      width: 2rem;
      height: 2rem;
    }
  }

  .list-info {
    padding-left: 0.5rem;

    span {
      display: block;
      font-size: 0.3rem;

      &:nth-child(1) {
        font-size: 0.4rem;
        color: #333333;
        font-family: SourceHanSansCN-Regular;
      }

      &:nth-child(2) {
        padding: 0.1rem 0;
        font-size: 0.3rem;
        background: #bbe5fe;
        border-radius: 0.3rem;
        text-align: center;
        margin: 0.28rem;
        width: 2.5rem;
        color: #0869ab;
        margin-left: 0;
      }

      &:nth-child(3) {
        font-size: 0.3rem;
        color: #999999;
      }
    }
  }

  .index-bottom {
    /* margin: 0rem 0px; */
    width: 90%;
    background: #fff;
    margin: 0 auto;
    font-size: 0.4rem;
    /* margin-top: 0.1rem; */
    margin-bottom: 2rem;
    padding: 0.3rem;
    text-align: center;
    border-radius: 0.2rem;
  }

  /deep/ .van-nav-bar__title {
    max-width: none;
  }

  .search-btn {
    width: 277px;
    height: 32px;
    background: #5babfb;
    border: none;

    .van-icon {
      font-size: 16px;
    }

    .van-button__text {
      font-size: 14px;
    }
  }

  .channel-tabs {
    /deep/ .van-tabs__nav {
      padding-left: 0;
      padding-right: 0;
    }

    /deep/ .van-tab {
      border-right: 1px solid #edeff3;
      border-bottom: 1px solid #edeff3;
    }

    /deep/ .van-tabs__line {
      bottom: 20px;
      width: 15px !important;
      height: 3px;
      background: #3296fa;
    }
  }

  .wap-nav-placeholder {
    width: 33px;
    flex-shrink: 0;
  }

  .wap-nav-wrap {
    position: fixed;
    right: 0;
    width: 33px;
    height: 43px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;

    .van-icon {
      font-style: 24px;
    }

    &:before {
      content: "";
      width: 1px;
      height: 43px;
      background: url("./line.png") no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .popups {
    text-align: center;
    margin-top: 0.2rem;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .popups-title {
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ce7202;
    letter-spacing: 0.2rem;
    margin-left: 0.2rem;
    font-size: 0.5rem;
  }

  .huangjin {
    font-size: 0.5rem;
    width: 100%;
    padding: 0.3rem;
    // background: linear-gradient(to bottom,#F6E891, #F3D756);
    color: rgb(255, 255, 255);
    border: 0px;
    letter-spacing: 0.2rem;
    text-align: center;
    font-weight: 700;
    color: #ce7202;
    // background: radial-gradient(#F6E891, #F3D756);
    background: url(../../assets/img2/huangjin.png) no-repeat;
    background-size: 100% 100%;
  }

  .bojin {
    font-size: 0.5rem;
    width: 100%;
    padding: 0.3rem;
    color: rgb(255, 255, 255);
    border: 0px;
    letter-spacing: 0.2rem;
    text-align: center;
    font-weight: 700;
    color: #7d4236;
    // background: radial-gradient(#FBDAC3, #E3A587);
    background: url(../../assets/img2/bojin.png) no-repeat;
    background-size: 100% 100%;
  }

  .huangjins {
    color: #ce7202;
    // background: radial-gradient(#F6E891, #F3D756);
    background: url(../../assets/img2/huangjin.png) no-repeat;
    background-size: 100% 100%;
    padding: 0.02rem 0.3rem;
    display: block;
    width: 1.7rem;
    margin: 0.1rem 0;
  }

  .bojins {
    color: #7d4236;
    // background: radial-gradient(#FBDAC3, #E3A587);
    background: url(../../assets/img2/bojin.png) no-repeat;
    background-size: 100% 100%;
    padding: 0.02rem 0.3rem;
    display: block;
    width: 1.7rem;
    margin: 0.1rem 0;
  }

  .yiling {
    background: url(../../assets/img2/yiling.png) no-repeat;
    background-size: 100% 100%;
    padding: 0.02rem 0.3rem;
    display: block;
    width: 1.7rem;
    margin: 0.1rem 0;
  }
}
.member {
  width: 100%;
  height: 1.94em;
  background: url(../../assets/img/member.png) no-repeat;
  background-size: 100% 100%;
}
.positioning {
  width: 89%;
  height: 2rem;
  margin: auto;
  position: fixed;
  background: #fff;
  border-radius: 0.1rem;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  left: 3%;
  top: 16%;
  box-shadow: 2px 2px 5px #666;
}
.post {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  padding-left: 3%;
  span:nth-child(1) {
    font-size: 0.4rem;
    color: #929090;
    font-family: SourceHanSansCN-Regular;
  }
  span:nth-child(2) {
    font-size: 0.4rem;
    color: #000;
    font-family: SourceHanSansCN-Regular;
  }
}
</style>
